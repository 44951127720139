//staging url
//export const API_BASE_URL = "https://103.164.67.226:3001/v1";
//staging socket url
//export const SOCKET_URL = "https://103.164.67.226:3001";

//staging url
export const API_BASE_URL = "https://apistaging.krishnais.com/v1";
//staging socket url
export const SOCKET_URL = "https://apistaging.krishnais.com";

//local url
//export const API_BASE_URL = "http://localhost:3001/v1";
//local socket url
//export const SOCKET_URL = "http://localhost:3001";
